import ResponsiveAppBar from './components/ResponsiveAppBar';
import { Route, Routes } from 'react-router';
import Home from "./components/Home";
import Notar from "./components/Notar";
import Services from "./components/Services";
import Info from "./components/Info";
import Contact from "./components/Contact";
import Imprint from "./components/Imprint";
import Privacy from "./components/Privacy";
import Security from "./components/Security";
import Footer from "./components/Footer";
import Carrer from "./components/Carrer";
import Blog from "./components/Blog";
import Decisions from "./components/decisions";
import SGNeubrandenburg from "./components/sgneubrandenburg";
import FGMecklenburgvorpommern from "./components/fgmecklenburgvorpommern";
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

function App() {
  // const [wasShowedMessage,setWasShowedMessage] = useState(localStorage.getItem("megssage_20240905") === "true");

  // const handleClose = () => {
  //   localStorage.setItem("megssage_20240905","true")
  //   setWasShowedMessage(true);
  // }



  return (
    <div className="App">
      {/* <Dialog open={!wasShowedMessage} onClose={handleClose}>
        <DialogContent>
          Am Donnerstag, den 05. September 2024, sind wir aufgrund einer betriebsinternen Veranstaltung telefonisch nicht erreichbar.
          <br/>Bitte schreiben Sie uns eine E-Mail.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Okay</Button>
        </DialogActions>
      </Dialog> */}

      <ResponsiveAppBar />
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Notar" element={<Notar />} />
        <Route path="/Leistungen" element={<Services />} />
        <Route path="/Info" element={<Info />} />
        <Route path="/Kontakt" element={<Contact />} />
        <Route path="/Karriere" element={<Carrer />} />
        <Route path="/Impressum" element={<Imprint />} />
        <Route path="/Datenschutz" element={<Privacy />} />
        <Route path="/Sicherheit" element={<Security />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Entscheidungen" element={<Decisions />} />
        <Route path="/SG-Neubrandenburg" element={<SGNeubrandenburg />} />
        <Route path="/FG-Mecklenburg-Vorpommern" element={<FGMecklenburgvorpommern />} /> 
        

      </Routes>

      <Footer />

    </div >
  );
}

export default App;
